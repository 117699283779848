<template>
  <div>
    <info-content
      step-num="third-step"
      :submit-flag="highLight"
      @submit="submit"
      @headerBack="headerBack"
    >
      <div class="step-bg" />
      <div class="data-form">
        <van-form>
          <van-field v-model="dataForm.card_name" :class="{'has-val':String(dataForm.card_name).length}" readonly class="form-item is-select" :placeholder="$t('bankInfo.bankName')" @click="gobankList()" />
          <van-field
            v-model="dataForm.bank_account"
            :maxlength="cardMaxlength"
            class="form-item"
            :placeholder="$t('bankInfo.bankNumber')"
            type="tel"
          >
            <template #button>
              <word-limit class="word-limit" :input-cont="dataForm.bank_account" :max-length="maxLength" />
            </template>
          </van-field>

          <van-field
            v-model="dataForm.affirm_bank_account"
            :maxlength="cardMaxlength"
            class="form-item m0"
            type="tel"
            :placeholder="$t('bankInfo.confirmBankNumber')"
          >
            <template #button>
              <word-limit :input-cont="dataForm.affirm_bank_account" :max-length="maxLength" />
            </template>
          </van-field>
        </van-form>
      </div>
    </info-content>
  </div>
</template>

<script>
import perfectInformation from '../perfectInformation'
import InfoContent from '../components/infoContent.vue'
import WordLimit from './components/wordLimit'

import { getSubBankInfo, getBankData } from '@/api/axios'

export default {
  components: {
    InfoContent,
    WordLimit
  },
  mixins: [perfectInformation],
  data() {
    return {
      cardMaxlength: 24,
      submitFlag: false,
      dataForm: {
        card_name: '', // 银行卡名称
        gate_id: '', // 银行卡简称
        bank_account: '', // 银行卡账号
        affirm_bank_account: ''
      }
    }
  },

  computed: {
    bankList: {
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'bankList',
          value: val
        })
      },
      get() {
        return this.$store.state.settings.bankList
      }
    },
    checkBank: {
      get() {
        return this.$store.state.settings.checkBank
      }
    },

    maxLength() {
      return this.dataForm.bank_type + '' === '1' ? 18 : 16
    },

    highLight() {
      return this.dataForm.bank_account.replace(/\s+/g, '').length >= 10 && this.dataForm.affirm_bank_account.replace(/\s+/g, '').length >= 10
    }
  },
  watch: {
    'dataForm.bank_account'(val) {
      this.$nextTick(() => {
        this.dataForm.bank_account = val.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
      })
    },
    'dataForm.affirm_bank_account'(val) {
      this.$nextTick(() => {
        this.dataForm.affirm_bank_account = val.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
      })
    },

    checkBank: {
      handler(val) {
        if (val) {
          const item = this.bankList.find(item => { return item.name === val })
          this.dataForm.card_name = item.name
          this.dataForm.gate_id = item.gate
        }
      },
      immediate: true
    }
  },

  created() {
    if (!this.bankList.length) {
      this.getbankPage()
    }
  },
  methods: {
    submit() {
      if (this.dataForm.bank_account !== this.dataForm.affirm_bank_account) {
        this.$toast({
          type: 'fail',
          message: this.$t('bankInfo.asBankTips'),
          className: 'toast-width',
          duration: 3000
        })
        return
      }
      this.getSubBankInfo()
    },
    // 提交信息
    async getSubBankInfo() {
      this.dataForm
      const params = { ...this.dataForm }
      params.bank_account = this.dataForm.bank_account.replace(/\s+/g, '')

      try {
        await getSubBankInfo(params)
        this.buryingPointParams.bankcard = {}
        this.buryingPointParams.bankcard.bankcard_duration = this.buryingPointParams.bank_account.bank_account_duration
        this.buryingPointParams.bankcard.bankcard_updatecount = this.buryingPointParams.bank_account.bank_account_updatecount
        this.isSubMitPoint = true
        if (this.$andirodData.supporder + '' === '1') {
          this.$H5Object.supporder()
        } else {
          this.$H5Object.cancleView()
        }
      } catch (err) {
        console.log(err)
      }
    },

    // 获取银行列表
    async getbankPage() {
      try {
        // 接口返回的让人很头疼啊 同一个值 提交时一个字段 埋点时一个字段 返回时又一个字段 (提交card_name 返回bank_name) (提交bank_account 埋点bankcard 返回bank_card)
        const { data } = await getBankData() || {}
        this.bankList = data?.bank_data || []

        this.dataForm.card_name = data?.bank_name || ''
        this.dataForm.gate_id = data?.gate_id || ''
        this.dataForm.bank_account = data?.bank_card
        this.dataForm.affirm_bank_account = data?.bank_card
      } catch (err) {
        console.log(err)
      }
    },

    headerBack() {
      if (this.$andirodData.bankBack) {
        this.$H5Object.goBack()
      } else {
        history.back()
      }
    },
    gobankList() {
      this.$router.push({
        name: 'BankList'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-top: 284px;
}
.step-bg{
  height: 196px;
  position: fixed;
  top: 88px;
  left: 0;
  right: 0;
  z-index: 2;
  background: url('~@/assets/images/baseInfo/bank-top-bg-icon.png') no-repeat center center #3D5CFFFF;
  background-size: 100%;
}
.data-form{
  position: relative;
  &::before{
    content:'';
    position: absolute;
    top: -284px;
    height: 382px;
    left: -40px;
    right: -40px;
    background: #3D5CFFFF;
  }
}

:deep(.van-form){
  background: #FFFFFF;
  border-radius: 24px;
  padding: 20px;
  position: relative;
}
:deep(.btn-box){
  padding-top: 60px;
}

:deep(.van-field__word-limit){
  margin-top: 40px;
  margin-right: 32px;
  font-size: 20px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #999999;
}
</style>
