<template>
  <div class="word-limit">{{ inputLength }}/{{ maxLength }}</div>
</template>

<script>
export default {
  name: 'WordLimit',
  props: {
    maxLength: {
      type: Number,
      default: 0
    },
    inputCont: {
      type: [Number, String],
      default: ''
    }

  },
  data() {
    return {
      inputLength: 0
    }
  },
  watch: {
    inputCont: {
      handler() {
        this.inputLength = this.inputCont.replace(/\s+/g, '').length
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.word-limit{
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -10px;
  font-size: 28px;
  display: none;
}
</style>
